async function resetStep (userId) {
  const response = await window.fetch(`${process.env.PUBLIC_URL}/api/${userId}/reset-step`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    }
  })

  if (response.status !== 200) {
    throw new Error('Uh oh, something went wrong there. Please report this error: ' + response.status + ' / ' + await response.text())
  }

  const json = await response.json()
  return json
}

export default resetStep
